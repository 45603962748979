import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // must import Router, else switch doesn't work
import Velocity from 'velocity-animate';
import Store from '../Store/Store';
import parse from 'html-react-parser';
import '../css/index.css';
import { imageNames } from '../Data/imageNames'
import bgImage from '../img/stopover-logo-all-white.svg';
import { content } from '../Data/content'

const bgUrl = `${ bgImage }`

const scrollTo = ( nodeId, duration = 500 ) => {
    let activities = document.getElementById( nodeId )
    Velocity( activities, "scroll", { duration:duration, easing:"easeOutQuad" } )
}

const FrontLink = props => (
  <div onClick={ () => { scrollTo( props.to, props.duration ) } } >
    <div className="front-link-hover" >
      { props.title }
    </div>
  </div>
)

const delay = 3000
const duration = 1000
let imageIndex = 0
let images = []

const FrontPage = props => {

  const ref0 = useRef()
  const ref1 = useRef()

  const [ tmp, setTmp ] = useState( 0 )
  const domain = Store.get( 'domain' )

  useEffect(() => {

      images = []
      const assets = require.context('../img', true);
      imageNames[ domain ].forEach( a => images.push( assets( a ) ) )
      setTmp( tmp + 1 )

      //window.scrollTo( { top: 0, left: 0 } );

      let animate = () => {
        const d = 1 - imageIndex % 2
        Velocity( ref0.current, { opacity: [ 1 - d, d ] }, { delay:delay, duration:duration } ) 
        Velocity( ref1.current, { opacity: [ d, 1 - d ] }, { delay:delay, duration:duration, complete:() => {
          imageIndex = imageIndex + 1
          if ( imageIndex >= images.length ) imageIndex = 0
          if ( ref0.current && ref0.current.src ) {
            if ( imageIndex % 2 === 1 ) ref0.current.src = images[ imageIndex ]
            else ref1.current.src = images[ imageIndex ]
            animate()
          }
        } } ) 
      }

      animate()
      

  }, [] )

  if ( !domain ) return

  return (

    <div id="frontpage" className="front-wrapper">

      <img ref={ ref0 } style={{ position:'absolute', top:0, left:0, width:'100%', height:'50vh', objectFit:'cover', opacity:1 }} alt="" src={ images[ images.length - 1 ] } />
      <img ref={ ref1 } style={{ position:'absolute', top:0, left:0, width:'100%', height:'50vh', objectFit:'cover', opacity:0 }} alt="" src={ images[ 0 ] } />

      <div className="front-shadow" />

      <div style={{ position:'absolute', width:'100%', maxWidth:'1240px', height:'50%', top:0, left:'50%', transform:'translateX(-50%)', overflow:'hidden', boxSizing:'border-box' }} >

        <div className="front">
          <div style={{ userSelect:'none', fontSize:'32px', fontWeight:600, padding:'0 0 20px 20px', color:'white', lineHeight:'36px' }}>
            { parse( content[ domain ].frontpage.title ) }
          </div>
          <div className="desktop-only" style={{ userSelect:'none', fontSize:'18px', fontWeight:'400', margin:'0 0 10px 20px', color:'white' }}>
            { parse( content[ domain ].frontpage.lead ) }
          </div>
          <div className="desktop-only" style={{ userSelect:'none', fontSize:'18px', fontWeight:'400', margin:'0 0 10px 20px', color:'white' }}>
            { parse( content[ domain ].frontpage.copy ) }
          </div>
        </div>

        <div className="products" style={{ textAlign:'right', padding:'0 20px 0 0' }} >
          <img 
            className="shadow" 
            style={{ position:'absolute', left:0, top:0, width:'150px', padding:'10px 0 0 20px', textShadow:'0 2px 4px rgba(0,0,0,0.5)'}} 
            draggable="false" 
            alt="Logo" 
            src={ bgUrl }
          />
          <div className="front-links" >
            { content[ domain ].blogs.enabled && 
              <FrontLink to="blog" duration={ 0 } title="Blog" />
            }
            <FrontLink to="instructions" duration={ 0 } title={ content[ domain ].site } />
            <FrontLink to="register" duration={ 0 } title="Register" />
            <FrontLink to="about" duration={ 0 } title="About Us" />
          </div>
        </div>
      </div>

    </div>
  )
}

export default FrontPage
