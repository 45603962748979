import React, { useState, useEffect } from 'react';
import Store from '../Store/Store';

const ToTop = props => {

  const [ top, setTop ] = useState( true )

  useEffect(() => {

    console.log( 'ToTop useEffect' )

    Store.set( 'top', false )

    window.onscroll = () => {
      if ( window.pageYOffset > 300 && Store.get( 'top' ) ) { 
        Store.set( 'top', false )
        setTop( false )
      }
      if ( window.pageYOffset <= 300 && !Store.get( 'top' ) ) { 
        Store.set( 'top', true )
        setTop( true )
      }
    }
  }, [] )

  if ( top ) return null

  return (
    <div 
      onClick={ () => { 
        window.scrollTo( { top: 0, left: 0 } ); 
        Store.set( 'top', true ); 
        setTop( true ) 
      } }
      style={{ 
        position:'fixed', 
        bottom:0,
        right:0,
        padding:'30px 20px',
        zindex:100,
        cursor:'pointer',
        userSelect:'none',
      }} 
    >

      <span 
        style={{ 
          background:'white', 
          padding:'16px',
          borderRadius:'50%',
          boxShadow:'0 2px 8px #88888888',
        }} 
        className="material-icons"
      >
        arrow_upward
      </span>

    </div>
  )
}

export default ToTop
