import Request from '../Request/Request';
import Store from '../Store/Store';

const Data = () => {

	const fetch = callBack => {

		Request.get( 'data', 
			result => { 

				let productLists = result.data.productLists || []
				productLists.forEach( ( r, i ) => {
					r.productListId = r.productlist_id
				} ) 
				Store.set( 'productLists', productLists )

				let blogs = result.data.blogs || []
				blogs.forEach( ( r, i ) => {
					r.productListId = r.productlist_id
				} ) 
				Store.set( 'blogs', blogs )

				let data = { productLists:productLists, blogs:blogs }
				callBack( data )

			} 
		)

	}

	return { 
		fetch:fetch, 
		productLists:Store.get( 'productLists' ) || [], 
		blogs:Store.get( 'blogs' ) || [], 
	}

}

export default Data
